import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user: User = {};

  constructor(private http: HttpClient, private router: Router) {}

  get user(): User{
    return this._user
  }

  logIn(email: string, password: string) {
    return this.http
      .post(`/api/auth/login`, {email, password})
      .pipe(map((res: any) => {
        localStorage.setItem('token', res.token);
        this._user = new User(res.user);
        return res;
      }));
  }
  adminLogInAs(email: string) {
    return this.http
      .post(`/api/auth/loginas`, { email })
      .pipe(map((res: any) => {
        localStorage.setItem('token', res.token);
        this._user = new User(res.user);
        return res;
      }));

  }
  getMe(){
    return this.http
      .get(`/api/auth/me`)
      .pipe(map((res: any) => {
        this._user = new User(res.user);
        return res;
      }));
  }
  logOut() {
    localStorage.removeItem('token');
    this._user = {};
    this.router.navigate(['/login']);

  }
  forgotPassword(email: string) {
    return this.http.post(`/api/auth/forgotpassword`, { email })
  }
  resetPassword(password: string, resetToken: string) {
    return this.http.put(`/api/auth/resetpassword/${resetToken}`, {password})
  }
  isAuthenticated(): boolean{
    return !!this.getAuthorizationToken();
  }
  getAuthorizationToken(){
    return localStorage.getItem('token');
  }
  userHasRole(role: string){
    return role == this.user?.role;
  }
  userHasAnyRole(roles: string[]){
    return roles.includes(this.user?.role);
  }
  
}
