import { ResetPasswordComponent } from './pages/login/reset-password/reset-password.component'

import { AuthGuard } from './auth/auth.guard'

import { NgModule } from '@angular/core';
import { RouterModule, Routes, NoPreloading, PreloadAllModules } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/login/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app/orders',
    pathMatch: 'full',
  }, 
  {
    path: 'app',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/layouts/main-layout/main-layout.module').then(m => m.MainLayoutModule)
      }
    ]
  }, 
  {
    path: 'login',
    title: 'Log In',
    component: LoginComponent,
  }, {
    path: 'login/forgotpassword',
    title: 'Forgot Password',
    component: ForgotPasswordComponent,
  }, {
    path: 'login/resetpassword/:token',
    title: 'Reset Password',
    component: ResetPasswordComponent,
  }, {
    path: '**',
    redirectTo: 'orders'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
