import { MatIconModule } from '@angular/material/icon'
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'success-snack',
    template: `
    <span class="success-snack">
        <mat-icon class="me-2">check</mat-icon> {{message}}
    </span>
        
    `,
    standalone: true,
    imports: [
        MatIconModule
    ],
    styles: [`
        .success-snack {
            color: #2dd65a;
        }
        .success-snack mat-icon{
            vertical-align: middle;
        }
    `]


})
export class SuccessSnack { 
    constructor(@Inject(MAT_SNACK_BAR_DATA) public message: string) { }
}