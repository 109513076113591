import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { environment } from "./environments/environment";

import * as Sentry from "@sentry/angular-ivy";


Sentry.init({
  environment: environment.production ? "production" : "development",
  dsn: "https://757a0811c1774c0a841c258ce921855f@o4504890286145536.ingest.sentry.io/4504890297483264",
  tracesSampleRate: 1.0,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
