import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  constructor(private auth: AuthService) { }

  model = {
    email: ''
  }

  error = '';

  isLoading = false;
  sentLink = false;

  sendResetPasswordLink() {
    this.isLoading = true;
    this.auth.forgotPassword(this.model.email).subscribe({
      next: (res: any) => {
        console.log(res)
        this.isLoading = false;
        this.sentLink = true;
      },
      error: (err: any) => {
        this.error = err.error.error;
        console.error(err);
        this.isLoading = false;
      }
    });
  }


}
