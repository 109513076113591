import { AuthService } from './../../auth/auth.service'
import { Component } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {

  constructor(private auth: AuthService){}

  isLoggedIn = false;
  ngOnInit(){
    // this.auth.getMe().subscribe({
    //   next: (res: any) => {
    //     console.log(res);
    //     console.log(this.auth.user)
    //     if(this.auth.user?._id){
    //       this.isLoggedIn = true;
    //     }
    //   }
    // })
  }

}
