<mat-toolbar color="primary" class="header">
    <span>AgriOrders</span>

</mat-toolbar>
<div class="container-fluid login-container">
    <div class="pt-5 text-center mb-3">
        <img class="brand" src="../../../assets/img/brand/AgriOrder.png">
        <!-- <h1 class="text-center">AriOrders</h1> -->
    </div>
    <div class="pt-4">
        <mat-card class="mx-auto">
            <mat-card-header class="mx-auto mt-3 mb-4">
                <h1 >Log In</h1>
            </mat-card-header>
            <mat-card-content>
                <form (ngSubmit)="logIn()">

                    <mat-error *ngIf="false">
                        The username and password were not recognized
                    </mat-error>
                    <mat-form-field>
                        <input matInput placeholder="Email" name="email" required [(ngModel)]="model.email">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput type="password" placeholder="Password" name="password" required
                            [(ngModel)]="model.password" password>
                    </mat-form-field>
                    <div class="text-danger">
                        {{error}}&nbsp;
                    </div>
                    <button style="width: 100%" mat-raised-button class="mt-2" color="primary">Login</button>
                    <br/>
                    <div class="mt-3">
                        <a class="link-info" [routerLink]="['forgotpassword']">Forgot Password</a>
                    </div>
                    
                </form>
            </mat-card-content>
        </mat-card>
    </div>

</div>
