<mat-toolbar color="primary" class="header">
    <span>AgriOrders</span>

</mat-toolbar>
<div class="container-fluid login-container">
    <div class="pt-5 text-center mb-3">
        <img class="brand" src="../../../assets/img/brand/AgriOrder.png">
        <!-- <h1 class="text-center">AriOrders</h1> -->
    </div>
    <div class="pt-4">
        <mat-card class="mx-auto">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading">
            </mat-progress-bar>
            <mat-card-header class="mx-auto mt-3 mb-4">
                <h1>Reset Password</h1>
            </mat-card-header>
            <mat-card-content>
                <form (ngSubmit)="resetPassword()" #resetPasswordForm="ngForm">
                    <mat-form-field class="mb-3" subscriptSizing="dynamic">
                        <input matInput type="password" placeholder="Password" name="password" required [(ngModel)]="model.password" #passwordInput="ngModel" passwordValidator>
                        <mat-error *ngIf="passwordInput.invalid && (passwordInput.dirty || passwordInput.touched)">
                            <span *ngIf="passwordInput.errors?.['passwordValidator']">
                                Must be at least 8 characters and include a uppercase letter a lowercase letter and a special
                                character.
                            </span>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <input matInput type="password" placeholder="Confirm Password" name="confirmPassword" required [(ngModel)]="model.confirmPassword" #confirmPasswordInput="ngModel" valueMatch="{{model.password}}">
                        <mat-error *ngIf="confirmPasswordInput.invalid && (confirmPasswordInput.dirty || confirmPasswordInput.touched)">
                            <span *ngIf="confirmPasswordInput.errors?.['valueMatch'] || confirmPasswordInput.errors?.['required']">
                                Passwords must match
                            </span>
                        </mat-error>
                    </mat-form-field>

                    
                    <button type="submit" style="width: 100%;" mat-raised-button class="mt-4" color="primary" [disabled]="!resetPasswordForm.valid">Reset Password</button>

                </form>
            </mat-card-content>
        </mat-card>
    </div>

</div>