<mat-toolbar color="primary" class="header">
    <span>AgriOrders</span>
</mat-toolbar>
<div class="container-fluid login-container">
    <div class="pt-5 text-center mb-3">
        <img class="brand" src="../../../assets/img/brand/AgriOrder.png">
    </div>
    <div class="pt-4">
        <mat-card class="mx-auto" *ngIf="!sentLink">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading">
            </mat-progress-bar>
            <mat-card-header class="mx-auto mt-3 mb-4">
                <h1>Forgot Password</h1>
            </mat-card-header>
            <mat-card-content>
                <form (ngSubmit)="sendResetPasswordLink()">
                    <mat-form-field>
                        <input matInput placeholder="Email" name="email" required [(ngModel)]="model.email">
                    </mat-form-field>

                    <button mat-raised-button class="mt-3" color="primary">Send password reset link</button>

                </form>
            </mat-card-content>
        </mat-card>
        <mat-card class="mx-auto" *ngIf="sentLink">
            <mat-card-header class="mx-auto mt-3 mb-4">
                <h1>Success</h1>
            </mat-card-header>
            <mat-card-content>
                You should be receiving an email shortly with instructions on setting your new password.
            </mat-card-content>
        </mat-card>
    </div>

</div>