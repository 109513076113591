import { Router } from '@angular/router'
import { AuthService } from '../../auth/auth.service'
import { Component } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(private auth: AuthService, private router: Router) { }

  model = {
    email: '',
    password: ''
  }

  error = '';

  ngOnInit(){
    console.log('test')
  }

  logIn() {
    this.auth.logIn(this.model.email, this.model.password).subscribe({
      next: (res: any) => {
        this.router.navigateByUrl('');
      },
      error: (err: any) => {
        this.error = err.error.error;
        console.error(err);
      }
    });
  }
}
