export class User {

    constructor(user) {
        this._id = user._id
        this.firstName = user.firstName
        this.lastName = user.lastName
        this.fullName = user.fullName
        this.email = user.email
        this.phoneNumber = user.phoneNumber
        this.role = user.role
        this.active = user.active
        this.receivesBillingNotices = user.receivesBillingNotices
        this.superAdmin = user.superAdmin
    }

    _id?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    email?: string;
    phoneNumber?: string;
    role?: string;
    active?: boolean;
    receivesBillingNotices?: boolean;
    superAdmin?: boolean;

    static isUser(user: any): user is User {
        console.log(user instanceof User)
        return user instanceof User;
    }
}
