import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
    selector: '[valueMatch]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ValueMatchValidatorDirective, multi: true }]
})
export class ValueMatchValidatorDirective implements Validator {
    @Input('valueMatch') value: string;

    validate(control: FormControl): { [key: string]: any } | null {
        const isValid = control.value === this.value;
        return isValid ? null : { valueMatch: { valid: false, value: control.value } };
    }
}