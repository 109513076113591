import { NavService } from './nav.service'
import { Router, NavigationEnd } from '@angular/router'
import { User } from './../../models/user'
import { AuthService } from './../../auth/auth.service'
import { Component, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay, withLatestFrom, filter } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from "../../../environments/environment"


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {

  @ViewChild('drawer') drawer: MatSidenav;
  
  constructor(private breakpointObserver: BreakpointObserver, public auth: AuthService, router: Router, public nav: NavService) {
    router.events.pipe(
      withLatestFrom(this.isHandset$),
      filter(([a, b]) => b && a instanceof NavigationEnd)
    ).subscribe(_ => this.drawer?.close());
  }


  get user(): User {
    return this.auth.user; 
  }

  isDevelopment(){
    return !environment.production;
  }

  isLoggedIn(){
    return this.auth.user?._id;
  }

  logOut(){
    this.auth.logOut();
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
}
