import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
    selector: '[passwordValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true }]
})
export class PasswordValidatorDirective implements Validator {
    static pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // Regex requires the password to contain at least one lowercase letter, one uppercase letter, one digit, one special character from the set @$!%*?&, and be at least 8 characters long.

    validate(control: FormControl): { [key: string]: any } | null {
        const isValid = PasswordValidatorDirective.pattern.test(control.value);
        return isValid ? null : { passwordValidator: { valid: false, value: control.value } };
    }
}