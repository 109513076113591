import { MatSnackBar } from '@angular/material/snack-bar'
import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorSnack } from 'src/app/components/snackbars/error-snack';
import { SuccessSnack } from 'src/app/components/snackbars/success-snack';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {


  model = {
    password: '',
    confirmPassword: ''
  }
  token = '';
  isLoading = false;

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute, private snackBar: MatSnackBar) { 
    this.token = this.route.snapshot.paramMap.get('token') || '';
  }


  resetPassword() {
    this.isLoading = true;
    this.auth.resetPassword(this.model.password, this.token).subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.snackBar.openFromComponent(SuccessSnack, { data: `Reset Password` });
        this.router.navigateByUrl('/login');
      },
      error: (err: any) => {
        this.isLoading = false;
        this.snackBar.openFromComponent(ErrorSnack, { data: err?.error?.error });
        console.error(err);
      }
    });
  }
}
