import { MatIconModule } from '@angular/material/icon'
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'error-snack',
    template: `
    <span class="error-snack">
        <mat-icon class="me-2">error</mat-icon> {{message}}
    </span>
        
    `,
    standalone: true,
    imports: [
        MatIconModule
    ],
    styles: [`
        .error-snack {
            color: #f54c4c;
        }
        .error-snack mat-icon{
            vertical-align: middle;
        }
    `]


})
export class ErrorSnack { 
    message = 'Error';

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) {
        console.log('data', data)
        if(data){
            this.message = data;
        }      
     }
}